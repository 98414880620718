<template>
  <v-app>
    <v-main class="loibus-conT">
      <v-row justify="center" align="center" class="fill-height">
        <div id="js-loibus-box" class="loibus-box">


          <v-progress-circular
              class="loibus-circle"
              :rotate="-90"
              :size="234"
              :width="24"
              :value="value"
              color="primary"
          >
            <v-btn :ripple="false" fab height="186" width="186" depressed color="#fff" class="text-h2"
                   @click="clearLoad()">
              <div v-if="$vuetify.breakpoint.smAndUp">
                <v-img width="120" :src="randomStickers()" :lazy-src="randomStickers()" class="mx-auto"></v-img>
              </div>
              <span v-else>
                {{ loadingIcon }}
              </span>
            </v-btn>
            <!--              {{ value }}%-->
            <!--              {{ loadingIcon }}-->
          </v-progress-circular>

          <div class="loibus-box-body">
            <div id="js-loibus-head" class="loibus-head font-weight-light">
              {{ loi_text }}
            </div>
            <div v-if="!loading" class="loibus-box-content">
              <div
                  v-for="url in filterUrl(false)" :key="url.url"

              >
                <v-btn text class="text-body-1" @click="openUrl(url.url)">
                  <v-tooltip left color="success">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="success" class="mr-1" v-bind="attrs"
                              v-on="on">mdi-check-circle
                      </v-icon>
                      <!--                      <v-img v-bind="attrs" v-on="on"-->
                      <!--                             lazy-src="https://cdn.jsdelivr.net/gh/hututu-tech/IMG-gongfeng@main/2022/02/02/61fa9c5050966.png"-->
                      <!--                             src="https://cdn.jsdelivr.net/gh/hututu-tech/IMG-gongfeng@main/2022/02/02/61fa9c5050966.png"></v-img>-->
                      <!--                      <span v-bind="attrs" v-on="on" class="mr-1">✅</span>-->
                      <svg style="width:24px;height:24px" viewBox="0 0 24 24" v-bind="attrs" v-on="on" class="mr-1"
                           color="#4caf50">
                        <path fill="currentColor"
                              d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z"/>
                      </svg>
                    </template>
                    <span>✅ 正常訪問</span>
                  </v-tooltip>
                  {{ url.url.split('.').slice(-2).join('.') }}
                </v-btn>
              </div>

              <div class="mt-1">
                <v-btn text class="text-body-1" @click="showAll = !showAll">
                  更多
                  <svg class="ml-1" v-if="!showAll" style="width:24px;height:24px" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"/>
                  </svg>
                  <svg class="ml-1" v-else style="width:24px;height:24px" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M7.41,15.41L12,10.83L16.59,15.41L18,14L12,8L6,14L7.41,15.41Z"/>
                  </svg>
                </v-btn>
              </div>
              <v-expand-transition>
                <div v-show="showAll">
                  <div v-for="url in filterUrl(true)" :key="url.url">
                    <v-btn text class="text-body-1" @click="openUrl(url.url)">
                      <v-tooltip left color="warning">
                        <template v-slot:activator="{ on, attrs }">
                          <!--                          <v-icon color="warning" class="mr-1" v-bind="attrs"-->
                          <!--                                  v-on="on">mdi-information-->
                          <!--                          </v-icon>-->
                          <svg v-bind="attrs" v-on="on" class="mr-1" style="width:24px;height:24px" viewBox="0 0 24 24"
                               color="#fb8c00">
                            <path fill="currentColor"
                                  d="M13,9H11V7H13M13,17H11V11H13M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z"/>
                          </svg>

                          <!--                          <span v-bind="attrs" v-on="on" class="mr-1">⚠️</span>-->
                        </template>
                        <span>⚠️ 梯子訪問</span>
                      </v-tooltip>
                      {{ url.url.split('.').slice(-2).join('.') }}
                    </v-btn>
                  </div>
                </div>
              </v-expand-transition>

            </div>

            <div v-else class="px-1" :class="{'px-13':$vuetify.breakpoint.xsOnly}">
              <v-progress-linear
                  color="primary"
                  indeterminate
                  rounded
                  height="6"
                  class="my-15"
              ></v-progress-linear>
            </div>
            <v-btn :block="!$vuetify.breakpoint.xsOnly" color="primary" height="45px" dark class="mt-6 font-weight-light px-16" x-large
                   @click="openUrl(rdUrl())" :class="{'mb-13':showAll && $vuetify.breakpoint.xsOnly}">
              立即前往
            </v-btn>
          </div>
          <div class="loibus-box-footer clearfix" v-show="!showAll || !$vuetify.breakpoint.xsOnly">
            <svg width="46px" height="42px" class="loibus-box-footer-icon">
              <circle fill-rule="evenodd" clip-rule="evenodd" fill="#7B7B7B" stroke="#DEDFE0" stroke-width="2"
                      stroke-miterlimit="10" cx="21.917" cy="21.25" r="17"></circle>
              <path fill="#FFF"
                    d="M22.907,27.83h-1.98l0.3-2.92c-0.37-0.22-0.61-0.63-0.61-1.1c0-0.71,0.58-1.29,1.3-1.29s1.3,0.58,1.3,1.29 c0,0.47-0.24,0.88-0.61,1.1L22.907,27.83z M18.327,17.51c0-1.98,1.61-3.59,3.59-3.59s3.59,1.61,3.59,3.59v2.59h-7.18V17.51z M27.687,20.1v-2.59c0-3.18-2.59-5.76-5.77-5.76s-5.76,2.58-5.76,5.76v2.59h-1.24v10.65h14V20.1H27.687z"></path>
              <circle fill-rule="evenodd" clip-rule="evenodd" fill="#FEFEFE" cx="35.417" cy="10.75" r="6.5"></circle>
              <polygon fill="#7B7B7B" stroke="#7B7B7B" stroke-linecap="round" stroke-linejoin="round"
                       stroke-miterlimit="10"
                       points="35.417,12.16 32.797,9.03 31.917,10.07 35.417,14.25 42.917,5.29 42.037,4.25 "></polygon>
            </svg>
            <div class="loibus-box-footer-text">
              <p>secure</p>安全加密
            </div>
          </div>
          <div class="mail-icon-btn" v-show="!showAll || !$vuetify.breakpoint.xsOnly">

            <v-tooltip right color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon color="primary" @click="dialog = true" v-bind="attrs" v-on="on"  x-large>
                  <!--                                  <v-icon>mdi-email</v-icon>-->
                  <span v-bind="attrs" v-on="on" class="mail-icon">📬</span>
                </v-btn>

              </template>
              <span>網址發佈郵箱 📨</span>
            </v-tooltip>
          </div>

          <v-dialog
              v-model="dialog"
              width="520"
          >
            <v-card>
              <v-card-title class="text-h5 primary white--text">
                📬 網址發佈郵箱
              </v-card-title>

              <v-card-text class="pa-7 text-body-1 font-weight-regular">
                <P class="text-center">投送任意郵件至</P>
                <p class="text-center"><v-chip color="primary" class="mx-1">loibus.service@gmail.com</v-chip></p>
                <p class="text-center">獲取最新網址</p>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    text
                    @click="dialog = false"
                >
                  OK
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </v-row>
    </v-main>
  </v-app>
</template>

<script>
import {fetch_urlList} from "@/api/url";

export default {
  name: 'App',
  data: () => ({
    loi_text: '正在拉取最新網址...',
    value: 0,
    interval: {},
    showAll: false,
    dialog: false,
    loading: true,
    urlList: [],
    url_suffix: '',
    loadingIcon: '🚌',
    // st: ["https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/1061180/f45506ec0f99799b0cd653366f0f01d4e6272c94.png",],// 火箭

    stickers: [
      //  new

      //猫
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/1203420/18f56cbc585b0979164534234276f91f47479dbb.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/1203420/ff11b39f7cef8de7593047e22c7ae77bcda25049.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/1203420/e58d362b8a21ac99d3043606f23932e6474a028e.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/1203420/af0ba79f70d498d4ceb1fb08f113d0ce693c52be.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/1203420/eec8feefd091e788a158fa26d0557b3d9cd749f2.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/1203420/bbc56545debc214970ea081bcc7e6eb383e7930e.png",


      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/1846860/1c251483495acf823fa6f043b0d4f8ff5eca3e1c.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/1348700/df5dc6769bb2088371b2ef7ca329d882b262639e.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/1065970/8a38f64b336e0543819538a023b2ae9454e1c665.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/1065970/949a58d53630a6aac519f5e1fcd5880c2c3800cf.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/1065970/4b61b9134e089780c87318e296ca6207f6f70567.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/1348700/d1bffd95de89a0a85307e06e819189de67e55afb.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/323850/4f39b45e30cf82f234ade6e211da3d505504479f.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/814540/7e56c40bcee2a4e27071c300cbcc8eba2ed5e4a0.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/1328670/46b17e0f1dfb49fe3553660c9dc130dc213f2a66.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/1217060/904b0982a1585b22d096da73b078ad1ebb6570ec.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/1335230/591807dd1aa5c200fcff659f4891feec51c28c73.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/1846860/836b0720508ee9d8c48661b706bf4da73446bc61.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/814540/9e5ec2ca4318da7cad4b3c3f376918a532b34588.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/814540/3ddaa290540f9f718e2ad8b6a13bcba0651ddd05.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/1328670/4ddd216285fda20367efa8dab36b3814072f7381.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/1071220/294e16fb37b8e0ff94696e53170f4c8a3696a4aa.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/1069740/29431a11b23215172f15d4653ea38f7ddfe01f49.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/978460/c4de761421c2152fd12be82464df49dfe42d2a86.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/216150/1e0034ff7c508de992b157be97935ff4318b58d4.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/814540/acf8964a0842b7e911d9b90383bde2cfa5988356.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/1145360/b6fb1ea388343548cb9bc238335a90232e1aa5a9.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/212200/b701d779568d9794af46fd51e419bb4e00d0b1be.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/1071220/4a93d0d2051e6f2ff0bfd5e45926dd80db89ef9b.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/1054490/f94e3d6f73eac8480a0251070b85aff6544f1397.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/588650/e7bc20dbc18a25ecb2670d3ff05a9fe4d02acabc.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/322330/34b128d380cb239955fc4f2037beafea96d8bff4.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/1418970/e09238996f38a85a3cc65b33b33f5f31fe3cb32e.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/743450/ef7f0bd6ce138f8fa02f6affcb48de1346d223e9.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/1335230/041f1e326c1d157d1313314fbb3560ac17695af1.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/628750/2a640ec3967c85a43fcacf6a14c9f5a74448eb06.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/629820/f5324176bfe2b49517041d132b10d822b19f7d82.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/1263950/d816b93d23983a4f6bf4174db30456373e680c5f.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/212200/168eef43ced42c0b626d95992823312386463552.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/1418970/5d0adcceb909ede21beba6b9fc1283f03b4e7242.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/743450/ded99507ad956050504d11751b9ac23f2df1a4ee.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/743450/22bf72b7810592ba5a5aa64c88769c56d1a74917.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/1492660/3ac6381203d2272e3ce0dbb5edcd902d8b7820c9.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/743450/c605d5704111c3e47a4c63cd7478456cbeb1f84c.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/1335230/a7491d3376e712d5cac9041c0c972507c422cce5.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/743450/1ba8a28276313453a610ee2dbc7c6d59dd5391e1.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/1263950/95263c6c8e742301dcf288db5b70071cf58d99fe.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/579720/7a8af062cc503eef0be5e800c6672fe7b649423a.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/619150/dc477e53e09f931b94b4e81cdbb4e7c66bb60456.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/375520/58d80edee454c1c1e3c5a0285e1810bb853a099d.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/1263950/bb7d0f75547e01caf03e6975430ea50da7f2ab78.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/1054490/058c0263d6f21fe02e141b3552c4df86b853a9d9.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/1325900/7b45542ecf59505ebc06f6dace83ba9bf509b17b.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/1325900/1fa5bc1828c8d02287cf666329b8dcd01f31a222.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/743450/087ca39ba0bd045f8dc1cf6786d8d74c42a95167.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/1709500/a1ee184abdf3e6ba30f06e650055a23aaa34145b.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/1492660/27265ceb67bda820724cde493c8116d66005fa4b.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/1792490/c5f30248d1795c0c49bf768dfe138eff4a26e07f.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/1709500/6f8afe39c2e822c7613b263d2fb516e7128e3dbd.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/323850/f5c10940d267c8e9e1d48a95eedff5f3c3695b84.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/761010/228bcbab43572cc1ce5e75fd363f960afa04e3fc.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/1792490/8536974ccbdce6d22c2c005a891fcea4ce86e14b.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/743450/4f0ff94a58fef630b00aec532b2b6068ede013f2.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/976590/fcc1e1c99f73d8af80566d790ae45d854f022af6.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/1263950/b18b17983906706bffe19b50532eb32c8dd68267.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/933820/9d78c9418bbea49b0bf01dae701c87af0248c425.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/1061180/f45506ec0f99799b0cd653366f0f01d4e6272c94.png", // 火箭
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/1328670/08b59f56540b7a7f1efea23454de411895ff3263.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/1071220/4a93d0d2051e6f2ff0bfd5e45926dd80db89ef9b.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/1328670/08b59f56540b7a7f1efea23454de411895ff3263.png",
      //少女
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/1406990/27bbb59771242b5641218320d96053d24e3246f4.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/1406990/03a4d8b30d4dcf0fa7de3e2b2a5c9dfefc2968da.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/1406990/bd4947fe6e9e55ffc0cbcefbca331c2d7407d408.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/1406990/2c40c63310162f1d40517cb46417f9bb6a0b7ddf.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/1406990/eb788eaa58a9d69de67092f7bcf6656327aeb22d.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/1406990/39b8bd89d7078596ac89a66ded5927192470578f.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/1406990/4d23c8a6ef290a766c6a9735de9d177941d0ff0d.png",
      "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/1406990/41600302fda54fba8c31db100a63e6b5f47bb68d.png",
      //  new end
      // "https://media.st.dl.pinyuncloud.com/steamcommunity/public/images/items/1406990/39b8bd89d7078596ac89a66ded5927192470578f.png",
      // "https://media.st.dl.pinyuncloud.com/steamcommunity/public/images/items/1406990/4d23c8a6ef290a766c6a9735de9d177941d0ff0d.png",
      // "https://media.st.dl.pinyuncloud.com/steamcommunity/public/images/items/322330/7c2f55feaeab332dab78a9e81838edc136bc78a8.png",
      // "https://media.st.dl.pinyuncloud.com/steamcommunity/public/images/items/1406990/eb788eaa58a9d69de67092f7bcf6656327aeb22d.png",
      // "https://media.st.dl.pinyuncloud.com/steamcommunity/public/images/items/1406990/41600302fda54fba8c31db100a63e6b5f47bb68d.png",
      // "https://media.st.dl.pinyuncloud.com/steamcommunity/public/images/items/1145360/8fad6e68d1d7ccaa20b6cc2d1da7cd34808d5d54.png",
      // "https://media.st.dl.pinyuncloud.com/steamcommunity/public/images/items/837470/b66feee7bdf9fd06dbf7ffac08c01bfa9f7c44ca.png",
      // "https://media.st.dl.pinyuncloud.com/steamcommunity/public/images/items/1406990/bd4947fe6e9e55ffc0cbcefbca331c2d7407d408.png",
      // "https://media.st.dl.pinyuncloud.com/steamcommunity/public/images/items/1314563/d81352903b22685b64ce300a42f69662c177c5fc.png",
      // "https://media.st.dl.pinyuncloud.com/steamcommunity/public/images/items/1117380/9fcf9f826b720885041be82ecbe15dabf90135ab.png",
      // "https://media.st.dl.pinyuncloud.com/steamcommunity/public/images/items/837470/7a22fbfc3278f0e0601df0db06270e9a2486e6b8.png",
      // "https://media.st.dl.pinyuncloud.com/steamcommunity/public/images/items/1117380/a605d0693d853a7ecf2fbde154d28a8822e40c58.png",
      // "https://media.st.dl.pinyuncloud.com/steamcommunity/public/images/items/1231290/a202f0f62d0ea22383e388dfdaad6398af7848a1.png",
      // "https://media.st.dl.pinyuncloud.com/steamcommunity/public/images/items/367520/06ed2ca1b318bca2cf83b35525a8a7708bac0d5f.png",
      // "https://media.st.dl.pinyuncloud.com/steamcommunity/public/images/items/1406990/27bbb59771242b5641218320d96053d24e3246f4.png",
      // "https://media.st.dl.pinyuncloud.com/steamcommunity/public/images/items/1231290/02e6c81271f23421b514fd8a9598fa7f7bf0f14c.png",
      // "https://media.st.dl.pinyuncloud.com/steamcommunity/public/images/items/322330/fc99377367983db6f9c152bed9d8099d8885b1e8.png",
      // "https://media.st.dl.pinyuncloud.com/steamcommunity/public/images/items/1069740/767e2335ee7cf5dc63a31678de938322b0a0c936.png",
      // "https://media.st.dl.pinyuncloud.com/steamcommunity/public/images/items/1314563/9d123555fb76efd356b837282399193a2c90aa85.png",
      // "https://media.st.dl.pinyuncloud.com/steamcommunity/public/images/items/837470/56b1d8112ab78decdee3e78e3110500c2dd5d366.png",
      // "https://media.st.dl.pinyuncloud.com/steamcommunity/public/images/items/837470/adad5d71ee86a456be85cf18db53bc1cefc38e7e.png",
      // "https://media.st.dl.pinyuncloud.com/steamcommunity/public/images/items/588650/0df029f1ad766d28d0b830a6b6c9df4a953a6dfb.png",
      // "https://media.st.dl.pinyuncloud.com/steamcommunity/public/images/items/1145360/0dd24aa67496f1716891cbe6452ee110a384027d.png",
      // "https://media.st.dl.pinyuncloud.com/steamcommunity/public/images/items/1069740/1878f87cd53d63c77e6b3eef2a1f7e1dbe4bac25.png",
      // "https://media.st.dl.pinyuncloud.com/steamcommunity/public/images/items/322330/8f51f73ab08a5d7d644b3a7db0d6afbc86f04d91.png",
      // "https://media.st.dl.pinyuncloud.com/steamcommunity/public/images/items/683320/cf520499fb5006b28b9710bde3c8fabc8880a0b7.png",
      // "https://media.st.dl.pinyuncloud.com/steamcommunity/public/images/items/683320/654c0df6f782c3e463298236dd35484384bf4bc9.png",
      // "https://media.st.dl.pinyuncloud.com/steamcommunity/public/images/items/322330/02e66860a53d88ad80c1536e3945bda8e8163a41.png",
      // "https://media.st.dl.pinyuncloud.com/steamcommunity/public/images/items/683320/e04204bf39f562be98a371d373c14995d76016df.png",
      // "https://media.st.dl.pinyuncloud.com/steamcommunity/public/images/items/683320/bd5493db059719abc84ae9237a1a58c1213f9074.png",
      // "https://media.st.dl.pinyuncloud.com/steamcommunity/public/images/items/546560/b64646bf23f3f020778d6a55fa80621cf7824ee2.png",
      // "https://media.st.dl.pinyuncloud.com/steamcommunity/public/images/items/1263950/4e0f9df2a984e2208844614afdfb59c8f903b7a6.gif",
    ],
  }),
  async created() {
    this.urlList = await fetch_urlList();
  },
  mounted() {
    document.body.style.overflowX = 'hidden';
  },
  methods: {
    openUrl(url) {
      window.location.href = url + this.url_suffix
    },
    filterUrl(condition) {
      return this.urlList.filter((url) => {
        return url.baned === condition
      })
    },
    rdUrl() {
      return this.filterUrl(false)[Math.floor(Math.random() * (this.filterUrl(false).length))].url
    },
    clearLoad() {
      clearInterval(this.interval)
    },
    randomStickers() {
      return this.stickers[
          Math.floor(Math.random() * (this.stickers.length - 1))
          ]
    }
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  watch: {
    urlList(val) {
      if (val.length > 0) {

        const queryString = window.location.search;
        if (queryString) {
          const urlParams = new URLSearchParams(queryString);
          const utm_source = urlParams.get("utm_source");
          if (utm_source) {
            localStorage.setItem("ad_source", utm_source)
          }
        }
        const ad_source = localStorage.getItem("ad_source") || "home";
        this.url_suffix = "?utm_source=" + ad_source;

        //--------------------
        this.loading = false
        this.loi_text = '正在前往LOIBUS...'
        this.interval = setInterval(() => {
          if (this.value === 100) {
            clearInterval(this.interval)
            this.loadingIcon = '🔄'
            this.loi_text = '正在跳轉中...'
            this.loading = true
            this.openUrl(this.rdUrl())
          } else {
            this.value += 10
          }

        }, 1500)
      } else {
        this.loading = true
        this.loi_text = '正在拉取最新網址...'
      }
    }
  },
};
</script>

<style scoped>
.loibus-conT {
    margin: 0;
    background-image: url("https://img14.360buyimg.com/ddimg/jfs/t1/189369/8/14801/1924652/60fd80feEf8af4df4/6bc39b6b6b9fe6c3.png");
    background-position: left top;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}


@media (max-width: 768px) {
  .loibus-conT {
    background-image: none; /* 不在移动端加载背景图片 */
  }
}

.loibus-box {
  position: relative;
  margin: 0 auto 0;
  padding: 180px 85px 22px;
  border-radius: 10px 10px 0 0;
  background: #FFF;
  box-shadow: 5px 9px 17px rgba(102, 102, 102, 0.75);
  width: 426px;
  color: #FFF;
  text-align: center
}

/* loibus-box 针对屏幕宽度小于或等于768px的设备 */
@media (max-width: 768px) {
  .loibus-box {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    border-radius: 0;
    box-shadow: none;
  }
}


.loibus-box p {
  margin: 0
}

.loibus-circle {
  position: absolute;
  top: -50px;
  left: 95px
}


/* loibus-circle 针对屏幕宽度小于或等于768px的设备 */
@media (max-width: 768px) {
  .loibus-circle {
    position: relative;
    top: auto;
    left: auto;
    margin: 40px auto 0px;
    transform: translateY(0);
  }
}


.mail-icon-btn{
  position: absolute;
  right: 23px;
  bottom: 20px;
}

@media (max-width: 768px) {
  .mail-icon-btn{
    position: fixed;
    right: 15px;
    bottom: 9px;
    margin: 0;
  }
}


.mail-icon{
  font-size: 30px;
}

@media (max-width: 768px) {
  .mail-icon{
    font-size: 38px;
  }
}


.loibus-box-body {
  margin: 35px 0
}

.loibus-head {
  color: #242424;
  font-size: 22px
}

.loibus-box-content {
  margin: 25px 0 14px;
  color: #7B7B7B;
  font-size: 18px
}

@media (max-width: 768px) {
  .loibus-box-content {
    margin: 25px 0 5px;
  }
}



.loibus-box-content p {
  line-height: 27px;
  margin-bottom: 5px;
}


.loibus-box-footer {
  margin: 0 auto;
  height: 42px;
  width: 120px
}

@media (max-width: 768px) {
  .loibus-box-footer {
    position: fixed;
    left: 0;
    bottom: 10px;
    margin: 0;
  }
}

.loibus-box-footer-icon {
  float: left;
  position: absolute;
  left: 30px;
}

.loibus-box-footer-text {
  float: left;
  border-left: 2px solid #EEE;
  padding: 3px 0 0 5px;
  height: 40px;
  color: #0B85CC;
  font-size: 12px;
  text-align: left;
  position: absolute;
  left: 80px;
}

@media (max-width: 768px) {
  .loibus-box-footer-icon{
    left: 20px;
  }

  .loibus-box-footer-text{
    left:70px;
  }
}


.loibus-box-footer-text p {
  color: #7A7A7A;
  font-size: 22px;
  line-height: 18px
}
</style>
