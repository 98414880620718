import axios from "axios";

export function fetch_urlList() {
    //location.protocol + '//' + location.host +
    return axios.get('/linker.json',)
        .then(function (response) {
            // console.log(response.data['urlList'])
            return response.data['urlList']
        })
        .catch(function (error) {
            console.log(error);
        });
}